/* https://codepen.io/Ana_Champion/pen/JRbeEL */

:root{
    --off-white: rgba(225,225,225)
}

.dialog-ovelay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    z-index: 999999
}
.dialog-ovelay .dialog {
    width: min(max(300px, 100%),400px);
    margin: 100px auto 0;
    background-color: white;
    box-shadow: 0 0 20px rgba(0,0,0,.3);
    border-radius: 3px;
    overflow: hidden
}
.dialog-ovelay .dialog header {
    padding: 10px 8px;
    background-color: lightgrey;
    /* border-bottom: 1px solid lightslategray */
}
.dialog-ovelay .dialog header h3 {
    font-size: 14px;
    margin: 0;
    color: gray;
    display: inline-block
}
.dialog-ovelay .dialog header .fa-close {
    float: right;
    color: rgb(94, 94, 94);
    cursor: pointer;
    transition: all .5s ease;
    padding: 0 2px;
    border-radius: 1px    
}

/* what is font awesome and why is it missing in codepens? !!!!!!!!!!!!!!!!!!! */
.dialog-ovelay .dialog header .fa-close:hover {
    color: yellow
}
.dialog-ovelay .dialog header .fa-close:active {
    box-shadow: 0 0 5px #673AB7;
    color: #a2a2a2
}
.dialog-ovelay .dialog .dialog-msg {
    padding: 12px 10px
}
.dialog-ovelay .dialog .dialog-msg p{
    margin: 0;
    font-size: 15px;
    color: #333
}
.dialog-ovelay .dialog footer {
    border-top: 1px solid #e5e5e5;
    padding: 8px 10px
}
.dialog-ovelay .dialog footer .controls {
    direction: ltr;
    float: right;
}
.dialog-ovelay .dialog footer .controls .button {
    padding: 5px 15px;
    border-radius: 3px;
    margin: 3px
}

.button-default {
    background-color: rgb(225, 225, 225);
    border: 1px solid rgba(190, 190, 190, 0.5);
    color: #5D5D5D;
}
.button-danger {
    background-color: #f44336;
    border: 1px solid #d32f2f;
    color: #f5f5f5
}
