.logo {
  color: white;
  font-size: 30px;
  font-family: MarkPro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.navbar-links {
  height: 100%;
  /* display: flex; */
}

.navbar-links ul {
  display: flex;
  margin: 0;
  padding: 0;
}
.navbarr {
  margin-bottom: 0 !important;
}

.navbar-account-button {
  width: 100px;
  text-align: center;
  padding: 7px 23px;
  margin: 0.25rem 0.75rem;
  background-color: white;
  color: black;
  border: none;
  outline: none;
  position: relative;
  border-radius: 100px;
  text-decoration: none;
  --border-size: 2px;
}
.navbar-account-button.pop::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* z-index: -1; */
  border-radius: 5px;
  border: 2px solid white;
  transition: top, left, right, bottom, 100ms ease-in-out;
}

/* .navbar-account-button.pop:hover::before,
.navbar-account-button.pop:focus::before {
    top: calc(var(--border-size) * -2);
    left: calc(var(--border-size) * -2);
    right: calc(var(--border-size) * -2);
    bottom: calc(var(--border-size) * -2);
} */

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .logo {
    display: block;
  }
  .marg-btm {
    margin-bottom: 9px;
  }
  .navbarr {
    z-index: 2;
    width: 100%;
    justify-content: space-between !important;
    padding-left:45px;
    padding-right:15px;
  }

  .navbar-links {
    /* this stuff should be move to navbar class */
    display: flex;
    width: 100%;
    height: 80vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--accent1);
    opacity: 50%;
    /* position: fixed; */
    left: 0;
    bottom: -100vh;
    transform: bottom 650ms;
    z-index: 2;
  }

  /* .navbar.open-menu {
    z-index: 2;
    width: 100%;
    height: 100vh;
  } */

  .navbar-links.open-menu {
    top: 0;
  }

  .navbar-links.open-menu ul {
    flex-direction: column;
    top: 0;
  }
  .icon-profile{
    flex-direction: column;
  }
  .navbar-account.open-menu {
    position: absolute;
    display: grid;
    grid-auto-flow: column;
    grid-column: span 2;
    grid-row: span 1;
    height: 20vh;
    width: 100%;
    bottom: 0px;
    align-items: center;
    justify-items: center;
  }
  .logout-display-none {
    display: none !important;
  }
  .logout-display-show {
    display: flex !important;
    position: absolute;
    top: 38px;
    background-color: #333;
    right: -15px;
    height: 97px;
    width: 120px;
    padding: 5px 0;
    z-index: 4;
  }
  /* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    display: block !important;
  } */
  .profile-icon {
    display: inline !important;
    float: left;
    margin-top: 4px;
  }
  .login-margin{
    margin-bottom: 5px;
  }
}

.profile-icon {
  display: none;
}
.logout-display {
  display: block;
}
/* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root{
  display: none;
} */
.navbar-links li :hover {
  color: #ed6221;
}
.navbar-links li a {
  display: block;
  text-decoration: none;
  color: white;
  padding:0.8rem 1.5rem;
  font-family: "Poppins";
  font-family: MarkPro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
a.navbar-account-button.pop {
  color: #fff;
  background: #ed6221;
  font-size: 15px;
  font-family: poppins;
  border-radius: 15px !important;
}
.navbarr {
  border-radius: 0;
}
.navbar-links {
  height: 100%;
  display: flex !important;
  align-items: center;
}
.mobldf .navbar-account {
  display: none;
}
li.submenudiv {
  position: relative;
  list-style-type: none;
}
.dropdown-content {
  position: absolute;
  background-color: #333;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
li.submenudiv .dropdown-content {
  display: none;
}
li.submenudiv:hover .dropdown-content {
  display: block !important;
}
.profile-logo {
  color: white;
  font-size: 21px !important;
  margin-right: 3px;
}
.icon-profile {
  display: flex;
  align-items: center;
}
.icon-profile2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-profile-border {
  width:100px;
  border: 1px solid white;
  border-radius: 100px;
  font-size: 15px;
  font-family: poppins;
  border-radius: 15px !important;
  color: white !important;
  padding: 6px 11px;
}
