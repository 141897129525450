/*
REFERENCES: 
Auth forms:  https://codepen.io/ibrahimjabbari/pen/qBWYBYv
Nav bar:  https://codepen.io/WebDevSimplified/pen/LqKQRK
hamburger:  https://codepen.io/codwerk/pen/bGGJzyq
buttons:  https://codepen.io/WebDevSimplified/pen/jOEorZN
tabs:  https://codepen.io/mirek-marchut/pen/wvqMBvW?editors=1100
*/

@import "./styles/hamburger.css";
@import "./styles/navbar.css";
@import "./styles/forms.css";
@import "./styles/starRating.css";
@import "./styles/boutTabs.css";
@import "./styles/searchBar.css";
@import "./styles/dialogBox.css";
@import "./styles/profile.css";
@import "./styles/spinner.css";

/* :root {
  --primary: rgb(57,33,255);
  --secondary: white;
  --accent1: rgb(176,23,69);
  
} */

/* #card-element {
  width: 100%;
  height: 100%;
}

#payment-element {
  width: 100%;
  height: 100%;
} */

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: MarkPro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  /* background: linear-gradient(var(--secondary), var(--primary)); */
  /* background-attachment: fixed; */
  width: 100%;
}
.hidden {
  display: none;
}

table{
  margin: auto;
}

td {
  width: 80px;
}

td:first-child, th:first-child {
  border-left: none;
 }

 tr:last-child {
  border-bottom: none;
 }


.font-family {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

#root {
  background: #eeeeee;
}
.logo-color {
  letter-spacing: 0.4rem !important;
  font-weight: 600;
  color: white !important;
}
.logo-color > span {
  color: #ed6221 !important;
}
/* @media only screen and (min-width: 320px) and (max-width: 767px) {
  form {
    padding: 0 20px !important;
  }
} */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  /* form {
    padding: 0 20px !important;
  } */
  .bout-tabs-padding {
    padding: 0px !important;
  }
  .not-enough {
    min-width: 390px !important;
    min-height: 190px !important;
  }
  .not-enough > div {
    height: 170px !important;
  }
  .showFencerBouts-2 {
    width: 30% !important;
    justify-content: flex-end !important;
    padding-right: 10px !important;
  }
  .fencer-detail {
    display: block !important;
    text-align: center;
  }
  .showFencerBoutsList .container {
    width: 300px !important;
    margin: 10px 5px !important;
  }
  .contactback {
    height: 100% !important;
  }
  .image_and_namesinvite1 {
    height: 400px !important;
  }
  .Tournament-title-t {
    font-size: 18px !important;

    margin: 20px 0 10px !important;
    padding: 0 7px !important;
  }
  span.crossTagsDiv2 {
    float: right;
    margin-right: 6px !important;
  }
  .Fencerbtn {
    width: 100px !important;
    padding: 4px 14px !important;
    font-weight: 400 !important;
  }
  .subscriptionpage {
    width: 270px !important;
  }
  .bout-title .container {
    width: 90% !important;
  }
  .profilediv1 .crossTagsDiv {
    position: absolute !important;
    right: 20px !important;
  }
  .font-20 {
    font-size: 14px !important;
  }
  label.bout-tab-radio span {
    font-size: 12px;
    font-weight: bold;
  }
  .bout-tabs label.bout-tab-radio {
    padding: 2px 8px !important;
  }
  span.toggle-label {
    display: none !important;
  }
  .toggleall {
    display: none !important
  ;
  }
  .card-category-1,
  .card-category-2,
  .card-category-3,
  .card-category-4,
  .card-category-5,
  .card-category-6 {
    margin-bottom: 16px !important;
  }
  .c-cl-t input#searchUSAFencing {
    width: 100% !important;
  }
  .Innovation {
    padding-top: 0px !important;
  }
  .bordersec {
    margin-top: 30px !important;
    margin-bottom: 0 !important;
  }
  .S-2 {
    padding: 0 0 !important;
  }
  input#searchUSAFencing {
    width: 90% !important;
  }
  .c-cl-t p {
    text-align: center !important;
  }
  .Heritagesec {
    margin: 20px 0 27px !important;
  }
  .MuiDialog-container {
    height: 570px !important;
    margin-top: 50px !important;
  }
  .numbered-sec {
    padding-top: 10px !important;
  }
  .numbered {
    padding-top: 10px !important;
  }
  .fencer-btns {
    padding: 2px 7px !important;
    margin: 0 7px !important;
  }
  .graph-section {
    justify-content: center !important;
  }
  .graph-section1 {
    width: 100% !important;
  }
  .navbar-account.open-menu {
    width: 200px !important;
    right: 0 !important;
    height: 76px;
  }
  h1.h1.Tournament-title.edit {
    position: absolute;
    top: -20px !important;
    right: 0 !important;
    width: 100% !important;
  }
  span.crossTagsDiv {
    z-index: 9999 !important;
    position: unset !important;
  }

  .Team {
    padding-top: 0 !important;
  }
  .back-div {
    padding: 20px !important;
    margin-top: 35px !important;
  }
  button.Connectbtn {
    font-size: 13px;
    padding: 10px 20px;
  }
  p.text-14 {
    width: 100% !important;
  }
  .numbered:before {
    display: none !important;
  }
  .fencer-heading {
    font-size: 22px;
  }
  .submitbtn {
    font-size: 11px !important;
    padding: 7px 16px !important;
    bottom: -68px;
    left: 11px;
  }
  .fencer-create-button {
    display: flex !important;
    justify-content: flex-end !important;
    margin-top: 5px;
  }
  button.submitbtn.black {
    height: 42px !important;
    width: 100px !important;
    display: block !important;
  }
  button.btn-position {
    position: unset !important;
  }
  .backtohomelink {
    padding: 4px 20px !important;
    font-size: 13px !important;
    height: 29px !important;
  }
  .numbered-sec:before {
    display: none !important;
  }
  .Innovation:before {
    display: none !important;
  }
  .Team:before {
    display: none !important;
  }
  .backgroundimagemaindiv {
    width: 90% !important;
    display: block !important;
    padding: 50px 0 !important;
  }
  .contactForm {
    padding: 13px !important;
    height: 475px;
  }
  .contactForm h2 {
    text-align: center;
  }
  .roww-flex {
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  h1.headingContact {
    font-size: 32px !important;
    margin-bottom: 10px !important;
  }
  .evant1 {
    width: 100% !important;
    margin: 0 0 20px !important;
  }
  .MuiDialog-paperWidthSm {
    width: 600px !important;
    padding: 0 !important;
  }
  .contactHead {
    width: 100% !important;
    padding-bottom: 40px !important;
    margin: 0 !important;
  }
  .Formsec {
    width: 100% !important;
  }
  .center111111 {
  
    display: block !important;
    
    
  }
  .navbar-account-button.open-menu.pop {
    padding: 4px 17px !important;
    color: blue;
    padding: 0.5rem;
    justify-items: center;
    font-size: x-large;
  }

  a.navbar-account-button.pop {
    color: #fff !important;
    font-size: 13px !important;
  }

  input.subsInput {
    margin: 10px 0 !important;
    width: 100% !important;
  }

  .navbar-links {
    display: none !important;
  }

  nav.mobldf {
    display: block !important;
  }

  .navbar-links {
    display: none !important;
  }

  .toggle {
    display: block !important;
  }
  .VideoUpload-section2 {
    flex-direction: column;
  }
  .videoUpload-videos {
    width: 73% !important;
    /* height: 110px !important; */
    margin: 5px auto !important;
  }
  h2.headingerofpage.tour {
    font-size: 24px !important;

    position: unset !important;
  }
  .fencerHeading-marginTop {
    margin-top: 0 !important;
  }
  .container1 {
    max-width: 1170px;
    width: 90% !important;
    margin-top: 25px;
    
  }

  .connectFromHome {
    width: 100% !important;
  }

  .center111111 {
    height: 350px !important;
  }

  #root {
    background: #f8f8f8;
    overflow: hidden !important;
  }

  .welcome-page {
    font-size: 32px !important;
  }

  .footerDetails {
    color: white;
    display: flex;
    width: 100% !important;
    margin: auto;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }

  .emailform {
    display: block !important;
  }

  .container.journal {
    display: BLOCK !important;
    justify-content: space-around;
  }

  .cardofimg {
    border: 1px solid #222;
    width: 160px;
    margin: 0 AUTO;
  }

  .footerpage {
    background-color: black;
    height: auto !important;
    width: 100% !important;
  }

  h2.bennartext {
    font-size: 20px !important;
    padding-top: 60px;
  }

  h2.connectedtitle {
    font-size: 24px !important;
  }

  h2.bennartext br {
    display: none !important;
  }

  .socialLinks {
    padding-bottom: 50px !important;
  }

  .socialLinks a {
    margin: 0 12px !important;
    font-size: 18px !important;
  }
  .footerContainer h2 {
    text-align: center !important;
    font-size: 20px !important;
  }

  .footerContainer h2 {
    padding: 26px 0 !important;
  }

  .emailform {
    width: 80% !important;
    margin: 0 auto 20px !important;
  }

  .connectFromHome h2 {
    font-size: 20px !important;
    margin-bottom: 20px !important;
  }

  .Registersec {
    min-width: 100% !important;
  }

  .loginPageWidthDiv {
    width: 300px !important;
    height: 100% !important;
  }

  .forgetpage {
    width: 300px !important;
  }

  form.tournament {
    padding: 0px 25px 25px !important;
  }

  .Tournament-title {
    font-size: 20px !important;
    padding-bottom: 20px !important;
  }

  h1.tourtext {
    font-size: 20px !important;
  }

  form.fencerform {
    width: 300px !important;
    margin: 0 auto !important;
    padding: 0 20px !important;
  }

  h1.Fencertitle {
    font-size: 20px !important;
  }

  h1.fencertext {
    font-size: 20px !important;
  }

  .navbar-links2 li {
    list-style: none;
    padding: 10px 0;
  }

  .navbar-links2 {
    background: #333;
    width: 200px;
    position: absolute;
    z-index: 999;
    height: 100%;
  }
  button.submitbtn.fencercreate {
    position: unset !important;
  }
  .background-text {
    position: absolute;
    top: 100px !important;
    left: 10px !important;
    font-size: 35px !important;
  }
  .home-headingg {
    font-size: 24px !important;
    letter-spacing: 6px;
    padding-top: 25px !important;
    padding-bottom: 5px !important ;
  }
  .home-page-flex {
    flex-direction: column;
    padding-bottom: 20px !important;
  }
  .home-page {
    padding: 5px 35px !important;
  }
  .home-page-flex > div {
    width: 100% !important;
  }
  .textAlign-center {
    padding-top: 0px !important;
  }
  .home-img {
    width: 100% !important;
    height: 60% !important;
    border-radius: 30px;
  }
  .col-reverse {
    flex-direction: column-reverse;
  }
  .spacing {
    padding: 0px !important;
  }
  .j-ml {
    margin-left: 0px !important;
  }
  .j-mr {
    margin-right: 0px !important;
  }
  .journal-heading {
    font-size: 22px !important;
  }
  .para-justify {
    font-size: 16px !important;
  }
  .footer-page {
    padding: 15px 8% !important;
    text-align: center;
  }
  .headdd {
    font-size: 28px !important;
  }
  .journal-page > p {
    font-size: 18px !important;
    padding: 25px !important;
  }
  .journal-flex {
    flex-direction: column;
    align-items: center;
  }
  .journal-image {
    height: 35rem !important;
  }
  .journal-flex-child {
    width: 100% !important;
    margin-bottom: 50px !important;
  }
  .footer-divider {
    border: 0.5px solid rgb(61, 61, 61);
    margin: 20px 0 !important;
  }
  .footer-page-grid {
    display: flex;
    flex-direction: column;
  }
  .footer-page-grid1 {
    width: 100% !important;
  }
  .footer-page-grid2 {
    width: 100% !important;
    margin: 30px 0;
    padding-left: 0px !important;
  }
  .footer-page-grid3 {
    width: 100% !important;
  }
  .footer-page-grid1 > p {
    line-height: 23px;
  }
  .footer-page-grid3 {
    padding-left: 0px !important;
  }
  .para-space {
    margin: 21px 0 !important;
  }
  .footer-page {
    padding: 15px 8% !important;
    text-align: center;
  }
  .headdd {
    font-size: 28px !important;
  }
  .footer-divider {
    border: 0.5px solid rgb(61, 61, 61);
    margin: 20px 0 !important;
  }
  .footer-page-grid {
    display: flex;
    flex-direction: column;
  }

  .footer-icon-div {
    justify-content: center;
  }
  .para-space {
    margin: 21px 0 !important;
  }
  .subscriptionDataflex {
    flex-wrap: wrap;
    gap: 0px !important;
  }
  .subscriptionpage {
    margin: 10px auto !important;
  }
  .reg-form-width {
    width: auto !important;
  }
}

/* @media only screen and (min-width: 767px) and (max-width: 900px) {
  .navbar-links {
    display: none !important;
  }
  .MuiDialog-container {
    height: 570px !important;
    margin-top: 50px !important;
  }
  p.text-14 {
    width: 100% !important;
  }
  .MuiDialog-paperScrollPaper {
    width: 100% !important;
  }
  nav.mobldf {
    display: block !important;
  }

  .navbar-links {
    display: none !important;
  }

  .toggle {
    display: block !important;
  }

  h2.headingerofpage.tour {
    font-size: 40px !important;

    position: unset !important;
  }

  .navbar-links2 li {
    list-style: none;
    padding: 10px 0;
  }

  .navbar-links2 {
    background: #000;
    width: 200px;
    position: absolute;
    z-index: 9999;
  }

  .mobldf .logo {
    display: none;
  }
} */

@media only screen and (min-width: 767px) and (max-width: 1170px) {
  .not-enough {
    min-width: 400px !important;
    min-height: 220px !important;
  }
  .not-enough > div {
    height: 185px !important;
  }
  .graph-section {
    justify-content: center !important;
  }
  .graph-section1 {
    width: 50% !important;
    /* background-color: antiquewhite; */
  }
  .graph-section2 {
    width: 50% !important;
    /* background-color: rgb(138, 130, 119); */
  }
  .graph-section3 {
    width: 50% !important;
    /* background-color: rgb(138, 130, 119); */
  }
  h2.headingerofpage.tour {
    position: unset !important;
  }
  .MuiDialog-container {
    height: 570px !important;
    margin-top: 50px !important;
  }
  .evant1 {
    width: 30% !important;
  }
  .container1 {
    width: 90% !important;
  }
  .numbered:before {
    display: none !important;
  }
  .numbered-sec:before {
    display: none !important;
  }
  .Innovation:before {
    display: none !important;
  }
  .Team:before {
    display: none !important;
  }
  form.fencerform {
    width: 90% !important;
    margin: 60px auto !important;
  }
  .loginPageWidthDiv {
    height: 100% !important;
  }
  .journal-page-flex {
    padding-bottom: 50px !important;
  }
  .footer-page-grid3 {
    padding-left: 0px !important;
  }
  .para-132 {
    line-height: 14px !important;
  }
  .textAlign-center {
    padding-top: 0px !important;
  }
  .subscriptionDataflex {
    flex-wrap: wrap;
    gap: 30px !important;
  }
  .subscriptionpage {
    margin: 15px auto !important;
  }
  .contactHead {
    width: 40% !important;
  }
  .Formsec {
    width: 60% !important;
  }
  .contactForm {
    padding: 25px !important;
  }
  button.submitbtn.black {
    height: 35px !important;
    padding: 2px 12px !important;
    font-size: small !important;
  }
  button.submitbtn.fencercreate {
    top: 1px !important;
  }
  .videoUpload-videos {
    width: 45% !important;
  }
  .reg-form-width {
    width: 650px !important;
  }
}

.button {
  cursor: pointer;
}

/* generic classes to scooch things around */
.mb {
  margin-bottom: 1rem;
  font-weight: 700;
}

/* Use for placing  */
.test {
  background-color: lime;
  color: red;
}

/* really just a card */
.container {
  background-color: white;
  margin: 20px auto;
  padding: 20px;
  width: 90%;
  /* width: min(100%, 387px); */
  min-height: 50px;
  justify-content: center;
  text-align: center;
  display: grid;
  border-radius: 10px;
  /* box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.7); */
  transition: all 0.2s;
  text-decoration: none;
  overflow: hidden;
  word-break: normal;
}

.submit-button:hover {
  opacity: 1;
}
.submit-button:focus {
  outline: none;
}
.auth-form > input {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  border-radius: 8px;
  border: 1px solid #00000047;
}
.reg-form-width {
  width: 700px;
}
.submit-button {
  background-color: #333;
  color: white;
  font-weight: 600;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
  font-size: 16px;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  border-radius: 50px;
}
.lesson-btnMargg {
  margin-top: 20px;
}

.google-login {
  justify-self: center;
  /* display: inline-block; */

  /* left: 50%; */
  /* transform: translateX(-50%); */
}

.form > * > input[type="text"] {
  border: none;
  border-bottom: 2px solid red;
}

.container-text {
  width: 100%;
  margin: 0 auto;
  background-color: white;
  margin: 20px auto;
  padding-top: 0px;
  min-height: 50px;
  display: grid;
  border-radius: 10px;
  /* box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.7); */
  transition: all 0.2s;
  text-decoration: none;
  overflow: hidden;
  word-break: normal;
  margin-top: 90px;
  position: relative;
}

.old-container {
  padding: 20px;
  width: min(500px, 100%);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  border-radius: 10px;
  background: white;
  -webkit-box-shadow: 2px 1px 21px -9px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 2px 1px 21px -9px rgba(0, 0, 0, 0.38);
  box-shadow: 2px 1px 21px -9px rgba(0, 0, 0, 0.38);
}

.detail {
  font-weight: 600;
  color: #000;
  font-size: 20px;
  margin: 6px 0;
}

/* previous next buttons */
.pagination-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.previous-button,
.next-button {
  background: var(--secondary);
  font-size: 2rem;
  color: var(--primary);
  border: 0;
  padding: 2rem 4rem;
  margin: 1rem 1.5rem;
  font-weight: 1000;
  border-radius: 15px;
  display: inline-block;
  text-align: center;
  justify-content: center;
  -webkit-box-shadow: 2px 1px 21px -9px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 2px 1px 21px -9px rgba(0, 0, 0, 0.8);
  box-shadow: 2px 1px 21px -9px rgba(0, 0, 0, 0.8);
}

.previous-button {
  padding: 2rem;
}

/* for testing rating button */
.my-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.on {
  color: #000;
}

.off {
  color: #ccc;
}

/* -------------------------------------ADD CSS---------------------------------------------------- */
.auth-form label {
  float: left;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  /* width: 100%; */
  text-align: left;
  margin-bottom: 5px;
  font-weight: 700;
}

form.auth-form input:focus-visible {
  outline: 1px solid #00000057;
}

.loginPageWidthDiv {
  width: min(100%, 387px);
  background: unset;
  box-shadow: unset;
  margin: 0 auto;
  min-height: 89vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* -------------------------------------ADD CSS---------------------------------------------------- */

.fornDataShwo input {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  /* background: #e0e0e0; */
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  border-radius: 10px;
  border: 1px solid #00000014;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.fornDataShwo label {
  text-align: left;
}

.fornDataShwo {
  display: flex;
}

/* .inputlabel {
  margin-right: 15px;
} */
.inputlabel {
  padding: 0 6px;
}

.loginpage {
  background-color: white;
  margin: 20px auto;
  padding: 20px;
  width: min(100%, 600px);
  width: min(100%, 387px);
  min-height: 50px;
  justify-content: center;
  text-align: center;
  display: grid;
  border-radius: 10px;
  transition: all 0.2s;
  text-decoration: none;
  overflow: hidden;
  word-break: normal;
}
.loginpage-skipnw {
  display: flex;
  justify-content: flex-end;
  font-size: 17px;
  font-weight: 600;
  color: #ed6221;
  cursor: pointer;
  text-decoration: underline;
}

h1.sgntitle {
  font-size: 24px;
  text-transform: capitalize;
  margin: 0 0 28px;
  font-weight: bold;
}

.forgetpage {
  width: min(100%, 387px);
  background: unset;
  box-shadow: unset;
  margin: 0 auto;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topforgetdiv {
  background-color: white;
  margin: 20px auto;
  padding: 20px;
  width: min(100%, 600px);
  width: min(100%, 387px);
  min-height: 50px;
  justify-content: center;
  text-align: center;
  display: grid;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.7);
  transition: all 0.2s;
  text-decoration: none;
  overflow: hidden;
  word-break: normal;
}

.topforgetdiv input {
  width: 100%;
  padding: 15px;
  display: inline-block;
  border: none;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  border-radius: 8px;
  border: 1px solid #00000047;
}

.topforgetdiv label {
  width: 100%;
  float: left;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin-bottom: 5px;
}

.emailicon i {
  color: #222;
  z-index: 0;
}

.login-input-user {
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  height: 70px;
}

.email-input input {
  width: 100%;
  padding: 0 15px;
  display: inline-block;
  border: none;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  border-radius: 0 6px 6px 0;
  border: 1px solid #ddd;
  height: 50px;
  border-left: none;
}

.email-input {
  width: 100%;
}

input#floatingInput:focus-visible {
  outline: none;
}

h1.Registertitle {
  margin: 10px 0 40px;
  font-size: 24px;
  font-weight: bold;
}

.Registersec {
  min-width: 800px;
}
.Signbtn {
  display: flex;
  justify-content: end;
}
.Signbtn button {
  width: 150px;
  margin-top: 30px;
}

a.navbar-account-button.pop:hover {
  text-decoration: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

h2.Propertiestitle {
  font-size: 18px;
  color: #000;
}

.texterror p {
  position: absolute;
  bottom: -18px;
  right: 0;
  font-size: 11px;
  color: red;
  font-weight: bold;
}

.texterror {
  position: relative;
}

/* .backtohome {
  text-align: right;
} */

.backtohomelink {
  border: 1px solid #000000a8;
  padding: 7px 36px;
  background: white;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  border-radius: 45px;
  height: 40px;
}
.addnewtournamnet {
  text-align: left;
  /* margin-bottom: 40px; */
}
.backbtntour {
  text-align: right;
}

.cookie-alert {
  display: flex;
  align-items: center;
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin-bottom: 10px;
}

.cookie-alert i {
  font-size: 20px;
  margin-right: 10px;
}

.cookie-alert p {
  margin: 0;
}

.backtotourament p.initialclass {
  float: left !important;
  margin-right: 5px;
}

.backtotournament {
  text-transform: capitalize;
}

.container.breadcrumbs {
  background: transparent;
  box-shadow: unset;
  text-align: left;
  justify-content: left;
  display: inherit;
}

.MuiDialog-paperScrollPaper {
  width: 45%;
}

span.crossTagsDiv {
  display: flex;
  justify-content: right;
  font-size: 25px;
  color: #222;
  position: absolute;
  top: 10px;
  right: 50px;
  cursor: pointer;
}

button.buttonback {
  padding: 7px 30px;
  background: #78a3fc;
  color: white;
  border-radius: 22px;
  border: none;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  padding: 13px 20px;
}

/* . {
  background-color: #10151B;
  background: url(https://static.pexels.com/photos/3797/black-and-white-sport-fight-boxer.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
} */

.center111111 {
  background-color: #10151b;
  /* background: url("../public/FinalStrip_original.jpg") */
  background: url("../public/FinalStrip_dark.jpg")
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  max-height: 80vh;
}

body {
  font-family: "Oswald", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.welcome-page {
  line-height: 0.95;
  color: #66fcf1;
  font-weight: 900;
  font-size: 150px;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.center111111 {
  /* position: absolute; */
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 581px; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 660px;
}

.btn1111 {
  margin: 0 auto;
  width: 170px;
  height: 60px;
  padding: 6px 0 0 3px;
  border: 2px solid #66fcf1;
  border-radius: 2px;
  background: none;
  font-size: 16px;
  line-height: 54px;
  color: #fff;
  letter-spacing: 0.25em;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: background 0.4s, color 0.4s;
  transition: background 0.4s, color 0.4s;
  cursor: pointer;
}

.btn1111:hover {
  background: #66fcf1;
  color: #10151b;
}

.navbarr {
  position: relative;
  height: 7.5rem;
  display: flex;
  flex-direction: row;
  background-color: #333;
  align-items: center;
  justify-content: space-around;
  z-index: 9 !important;
}

.editDeletebtn {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* 12-05-2023 */
.backtohome {
  /* text-align: right; */
  /* display: flex; */
  width: 90%;
  margin: 0 auto;
  align-items: center;
  position: relative;
  padding: 20px 0;
}

/* .backbtn-1 {
  width: 50%;
} */
.acceptbtn4 {
  background: #fff;
  color: #000;
  padding: 4px 16px;
  border-radius: 40px;
  font-size: 14px;
  font-weight: 500;
}

p.accepttext {
  margin: 0;
  font-size: 14px;
}

.crossIcon {
  width: 26px;
  position: absolute;
  top: 27px;
  right: 27px;
}

.emailicon {
  width: 51px;
  background: #ed6221;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin: 0 0;
  position: relative;
  z-index: 0;
  border-radius: 6px 0 0 6px;
}

button.buttonback.Tour {
  position: absolute;
  top: 57px;
  right: 38px;
}
button.submitbtn.fencercreate {
  position: absolute;
  right: 40px;
  top: -5px;
}

.Tournament-title {
  font-weight: 600;
  font-size: 28px;
  color: #000;
  text-shadow: 1px 1px 1px #00000040;
  text-align: center;
  padding-bottom: 40px;
}

h1.tourtext {
  font-weight: 600;
  font-size: 28px;
  color: #000;
  text-shadow: 1px 1px 1px #00000040;
}

.addtournamentbtn {
  width: 200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

form.tournament input {
  border: 1px solid #ddd;
  height: 45px;
  padding: 0 10px;
  font-size: 13px;
}

form.tournament select {
  border: 1px solid #ddd;
  height: 45px;
  padding: 0 10px;
  font-size: 13px;
}

.form-input label {
  position: absolute;
  top: -22px;
  left: 0px;
  font-size: 13px;
  color: black;
  transition: all 0.5s ease-in-out;
}

.form-input > label.jumped {
  top: -18px;
}

.form-input input:focus {
  border: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.tournament label {
  font-size: 13px !important;
  top: -18px !important;
}

.form-input .arrow {
  display: none;
}

.profileImgDialognew .tournament {
  padding: 0 !important;
}

.profileImgDialognew .Tournament-title {
  font-size: 24px;
  padding-bottom: 15px;
}

h1.nametitle {
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 28px;
}

.eventform .form-input {
  width: 100%;
  float: left;
}

.eventtitle {
  font-size: 28px;
  font-weight: bold;
  color: #000;
  margin-bottom: 60px;
}

.eventform input {
  border: 1px solid #ddd;
  height: 45px;
  padding: 0 10px;
  font-size: 13px;
}

form.eventform select {
  height: 45px;
  border: 1px solid #ddd;
  font-size: 13px;
}

.eventform label {
  font-size: 13px !important;
  top: -20px !important;
}

.addevent {
  width: 200px;
}

.backtotournament span {
  color: #000;
}

.navbar-account-button.pop::before {
  border: none;
}

.backtotournament {
  font-size: 16px;
  font-weight: bold;
  color: #0000008c;
  cursor: pointer;
}
.eventspage {
  cursor: pointer;
  height: 170px;
}
.evant1 {
  width: 23%;
  float: left;
  background: rgb(255, 94, 0);
  background: linear-gradient(
    180deg,
    rgba(255, 94, 0, 1) 0%,
    rgba(157, 52, 0, 1) 100%
  );
  margin: 10px;
  padding: 10px 20px;
}

.editDeletebtn button {
  padding: 3px 11px;
  border: none;
  margin: 0 2px;
  border-radius: 4px;
}

.evant1 h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
}

.evant1 p {
  color: #fff;
}

.allevents {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 38px 42px;
}

.eventsdialog .eventform input {
  border: 1px solid #ddd;
  height: 45px;
  padding: 0 10px;
  font-size: 13px;
}

p.loginerror {
  margin: 0;
  text-align: right;
  font-size: 13px;
  color: red;
}

.eventsdialog .eventtitle {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-bottom: 42px;
  text-align: center;
}

.eventsdialog .form-input {
  margin-bottom: 25px;
  min-height: 45px;
}

h3.evntnttitle {
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  padding: 20px 0;
  color: #000;
}

h3.bouttitle {
  margin: 0;
  padding: 10px 0;
  font-weight: bold;
  color: #fff;
  font-size: 24px;
}

h1.bouttitle {
  color: #000;
  font-size: 32px;
  font-weight: bold;
}

.bout-tab-content input {
  border: 1px solid #ddd;
  height: 45px;
  padding: 0 10px;
  font-size: 13px;
}

form.newbout h1 {
  font-size: 25px;
  margin-bottom: 40px;
}

section.bout-tab-content h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 15px;
}

.bout-tab-content select {
  height: 50px;
  border: 1px solid #ddd;
  font-size: 13px;
}

.toggle-checkbox:checked + .toggle-switch {
  background: #1963d3;
}

.Fenceradd {
  width: 200px;
  /* float: left; */
}

.winnerbtn {
  margin-left: 8px;
  background: none;
  border: none;
  width: 170px;
  height: 48px;
  border-radius: 45px;
  font-size: 15px;
  background: #ed6221;
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: bold;
}

.toggle1 {
  margin: -20px 0 27px;
}

.container1 {
  max-width: 1170px;
  margin: 0 auto;
  clear: both;
  width: 100%;
}

img.w-full {
  width: 100%;
}

span.font-normal {
  font-weight: 400;
  letter-spacing: 6px;
}

.Heritagesec {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin: 39px 0 27px;
  font-family: "Roboto", sans-serif;
}

.textsec {
  color: #303030e3;
  font-size: 16px;
  line-height: 28px;
  font-family: MarkPro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.max-w-365 {
  max-width: 365px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.numbered {
  position: relative;
  padding-top: 35px;
}

.numbered-sec {
  position: relative;
  padding-top: 30px;
}

.bordersec {
  margin-top: 50px;
}

.S-2 {
  padding: 50px 0;
}

.Innovation {
  position: relative;
  padding-top: 30px;
}

h2.bennartext {
  color: #fff;
  font-size: 42px;
  font-weight: 700;
  letter-spacing: 4px;
  margin: 0;
  font-family: poppins;
  text-transform: uppercase;
}

.Innovation:before {
  content: "03";
  z-index: -1;
  margin-left: -57px;
  font-size: 90px;
  position: absolute;
  left: 0;
  top: 0;
  color: #f5f3f280;
  font-weight: 700;
}

.Team {
  position: relative;
  padding-top: 30px;
}

.Team:before {
  content: "04";
  z-index: -1;
  margin-left: -57px;
  font-size: 90px;
  position: absolute;
  left: 0;
  top: 0;
  color: #f5f3f280;
  font-weight: 700;
}

.scrolled .menu {
  /* position: fixed; */
  width: 100%;
  top: 0;
  z-index: 99999999999 !important;
}

h1.fencertext {
  font-size: 28px;
  font-weight: bold;
  margin: 30px 0 0;
}

p.bennartext {
  max-width: 480px;
  color: #fff;
  letter-spacing: 4px;
  font-size: 15px;
  font-family: "Poppins";
}

h2.connectedtitle {
  color: #ed6221;
  margin: 6px 0 6px;
  font-size: 42px;
  font-weight: 700;
  letter-spacing: 4px;
  font-family: "Poppins";
}
/* 
 {
  color: #ed6221;
  margin: 6px 0 6px;
  font-size: 42px;
  font-weight: 700;
  letter-spacing: 4px;
  font-family: "Poppins";
} */

a.morebtn {
  background: #ed6221;
  padding: 11px 30px;
  display: inline-block;
  color: #fff;
  font-size: 16px;
  margin-top: 30px;
  border-radius: 20px;
  font-family: "Poppins";
}
.Fencertitle {
  font-weight: bold;
  color: #000;
  font-size: 24px;
}

form.fencerform {
  width: 750px;
  margin: 60px 0;
}
.regi-fencerpage {
  width: 100% !important;
}

.fencerform .form-input {
  width: 100%;
  float: left;
}

.fencerform input {
  border: 1px solid #ddd;
  height: 45px;
  padding: 0 10px;
  font-size: 13px;
  border-radius: 4px;
}

.fencerform select {
  height: 50px;
  border: 1px solid #ddd;
  font-size: 13px;
}

.Fencerbtn {
  width: 150px;
}

input#searchUSAFencing {
  width: 100%;
  border: 1px solid #ddd;
  height: 45px;
  margin: 7px 0;
  border-radius: 6px;
  padding: 0 5px;
}

.fencerform label {
  font-size: 13px !important;
}
.fencer-create-button {
  position: relative;
}
button.submitbtn.black {
  background: #333;
  height: 48px;
  align-items: center;
  display: flex;
  color: #fff !important;
}
h2.headingerofpage {
  display: flex;
  justify-content: center;
  font-size: 40px;
  font-weight: 600;
  font-family: "Poppins";
}

.graph-section {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.graph-section1 {
  width: 33%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.fencer-chart {
  display: flex;
  justify-content: start;
  align-items: baseline;
}
.not-enough {
  min-width: 420px;
  min-height: 250px;
  text-align: center;
  padding: 5px 50px;
 justify-content: center;
}
.not-enough > div {
  border: 2px solid #dbdcdc;
  border-radius: 2px;
  width: 25%;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #858686;
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;
}
.fencer-btns {
  background-color: #333;
  border: none;
  color: white;
  padding: 3px 17px;
  margin: 0 10px;
}

.fencer-btns-line {
  text-decoration: line-through;
  opacity: 0.7;
}
.fencer-btns:focus {
  outline: none;
}
.col {
  margin: 15px 0;
}
.center111111.contactback {
  height: 100vh;
}

h1.headingContact {
  font-size: 60px;
  font-family: "Poppins";
  margin: 0;
}

strong {
  color: #ed6221;
}

.numbered:before {
  content: "01";
  z-index: 0.2;
  margin-left: -57px;
  font-size: 90px;
  position: absolute;
  left: 0;
  top: 0;
  color: #6d5c54a6;
  font-weight: 700;
  z-index: 00001;
  opacity: 0.2;
}

.numbered-sec:before {
  content: "02";
  margin-left: -57px;
  font-size: 90px;
  position: absolute;
  left: 0;
  top: 0;
  color: #6d5c54a6;
  font-weight: 700;
  z-index: 00001;
  opacity: 0.2;
}

.Innovation:before {
  content: "03";
  z-index: 00001;
  opacity: 0.2;
  margin-left: -57px;
  font-size: 90px;
  position: absolute;
  left: 0;
  top: 0;
  color: #6d5c54a6;
  font-weight: 700;
}

.Team:before {
  content: "04";
  z-index: 00001;
  opacity: 0.2;
  margin-left: -57px;
  font-size: 90px;
  position: absolute;
  left: 0;
  top: 0;
  color: #6d5c54a6;
  font-weight: 700;
}
.footer-page {
  background-color: #333;
  color: #b9b8b8;
  padding: 15px 8%;
}
.headdd {
  letter-spacing: 0.4rem !important;
  font-weight: 700;
  color: white !important;
}
.heading {
  color: #ed6221 !important;
}
.footer-divider {
  border: 0.5px solid rgb(61, 61, 61);
  margin: 30px 0;
}
.footer-page-grid {
  display: flex;
}
.footer-page-grid1 {
  width: 30%;
}
.footer-page-grid2 {
  width: 50%;
}
.footer-page-grid3 {
  width: 20%;
}
.footer-page-grid1 > p {
  line-height: 23px;
  margin-bottom: 17px;
  padding-right: 37px;
}
.footer-page-grid2 {
  display: flex;
  flex-direction: column;
  padding-left: 9%;
}
.grid2-flex {
  display: flex;
}
.grid2-flex > div {
  width: 50%;
}
.footer-menu li {
  list-style-type: none;
}
.footer-menu li a {
  color: #b9b8b8;
}
.footer-menu li a:hover {
  color: #ed6221;
}
.linklink {
  line-height: 30px;
  color: #b9b8b8;
}
.linklink > span {
  line-height: 30px;
  color: #b9b8b8;
}
.footer-page-grid3 {
  padding-left: 50px;
}
.footer-icon-div {
  display: flex;
  align-items: center !important;
}
.heading-color {
  color: white !important;
  font-weight: 500;
  letter-spacing: 0.2rem;
  margin-bottom: 10px;
}
.mail-icon {
  font-size: 17px;
  margin-right: 3px;
}
.mail-link {
  display: inline-block;
  text-decoration: none;
  color: #b9b8b8;
}
/* .footer-icon-div:hover .mail-icon {
  color: #ed6221;
}
.footer-icon-div:hover .mail-link {
  color: #ed6221;
} */
.mail-link:hover {
  color: #ed6221;
}
.footer-page > p {
  text-align: center;
  font-weight: 500;
}
.home-page {
  background-color: #252525;
  color: white;
  padding: 0 8%;
}
.home-headingg {
  font-size: 43px;
  text-align: center;
  letter-spacing: 8px;
  padding-top: 50px;
  padding-bottom: 10px;
  font-weight: 600;
}

.home-page-flex {
  display: flex;
  padding: 20px 0;
}
.home-page-flex > div {
  width: 50%;
}
.textAlign-center {
  display: flex;
  align-content: center;
  flex-direction: column;
  padding-top: 50px;
}
.j-ml {
  margin-left: 15px;
}
.j-mr {
  margin-right: 15px;
}
.home-heading {
  font-size: 27px;
  position: relative;
  z-index: 1;
  letter-spacing: -0.9px;
}
.para-justify {
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 28px;
  font-family: MarkPro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
.home-img {
  width: 100%;
  height: 90%;
  border-radius: 30px;
  /* object-fit: cover; */
}
.journal-page {
  background-color: #252525;
  color: white;
  padding: 0 8%;
}
.journal-page-heading {
  padding: 50px;
  margin-top: 0;
}
.journal-flex {
  display: flex;
  gap: 2rem;
}
.journal-flex-child {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 110px;
}
.journal-image {
  width: 100%;
  height: 250px;
  border-radius: 23px;
  border: 2px solid white;
}
.journal-flex-child:hover .journal-image {
  border: 2px solid #ed6221;
}

.journal-flex-child:hover .journal-button {
  border: 2px solid #ed6221;
  background-color: #ed6221;
}
.journal-button {
  width: 15rem;
  height: 4rem;
  background-color: #252525;
  border: 2px solid white;
  font-weight: 500;
  color: white;
  border-radius: 100px;
  margin-top: 20px;
}
.journal-button:focus {
  outline: none;
}
input.subsInput {
  border: none;
  border-bottom: 2px solid white;
  padding: 9px;
  margin: 10px;
  width: 300px;
  background: black;
  color: #fff;
}

form.tournament {
  padding: 0px 70px 25px;
  position: relative;
}

.footerDetails {
  color: white;
  display: flex;
  width: 80%;
  margin: auto;
}

h1.h1.Tournament-title.edit {
  position: absolute;
  top: -33px;
  right: 142px;
}

.fencerView {
  position: relative;
}

.emptydiv {
  height: 28px;
}

.contactHead {
  color: white;
  width: 50%;
  display: flex;
  align-items: center;
  padding-right: 50px;
}
.Formsec {
  width: 50%;
}
.submitbtn {
  color: #fff;
  background: #ed6221;
  font-size: 16px;
  /* font-weight: 600; */
  padding: 12px 30px;
  margin: 0.25rem 0.75rem;
  color: black;
  border: none;
  outline: none;
  /* position: relative; */
  border-radius: 100px;
  text-decoration: none;
  --border-size: 2px;
}

.contactForm {
  width: 100%;
  background: #333333c9;
  border-radius: 6px;
  padding: 40px;
}
.roww-flex {
  display: flex;
  width: 100%;
}
.contactForm h2 {
  color: white;
  margin-top: 0px !important;
}

.sec {
  width: 20%;
}

.contactDiv {
  background: #333;
  padding: 20px;
}

.sec a {
  display: flex;
  justify-content: center;
  color: #c9ccd1;
  font-family: "Poppins";
}

textarea.contactdes {
  width: 100%;
  height: 90px;
}

.container.journal {
  display: flex;
  justify-content: space-around;
}

a.navbar-link.jur {
  background: #333;
  padding: 8px;
  border-radius: 15px;
  color: white;
}

.socialLinks {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.socialLinks a {
  margin: 25px;
  font-size: 26px;
  color: white;
}

.socialLinks a:hover {
  color: #ed6221;
}

button.submitbtn {
  background: #ed6221;
  height: 38px;
  align-items: center;
  display: flex;
  color: #fff;
  font-family: poppins;
  justify-content: center;
  position: relative;
}
button.submitbtn:focus {
  outline: none;
}
button.submitbtn.black:focus {
  outline: none;
}
.sec a:hover {
  color: #ed6221;
}

a.navbar-link {
  color: white;
}

.addnewtournamnet {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h2.headingerofpage.tour {
  display: flex;
  justify-content: center;
  font-size: 40px;
  font-weight: 600;
  position: absolute;
  top: -20px;
  left: 440px;
  font-family: "Poppins";
}

.emailicon i {
  color: #fff;
  z-index: 0;
}

img.imggoal {
  width: 70px;
  object-fit: contain;
  aspect-ratio: 1/2;
}

a.navbar-link.jur {
  background: #333;
  padding: 8px;
  border-radius: 15px;
  color: white;
  display: block;
}

a.navbar-link.jur.card {
  background: #333;
  padding: 8px;
  border-radius: 0px !important;
  color: white;
  display: block;
}

.basic-card-aqua {
  background: rgb(255, 94, 0);
  background: linear-gradient(
    180deg,
    rgba(255, 94, 0, 1) 0%,
    rgba(157, 52, 0, 1) 100%
  );
}

.cardofimg {
  border: 1px solid #222;
  width: 160px;
}

.cardofimg:hover {
  border: 1px solid #222;
  width: 160px;
}

button.delete-btn {
  background: inherit;
  color: white;
}
.card-link.action-btn {
  display: flex;
  justify-content: center;
}
button.edit-btn {
  background: inherit;
  color: white;
}

.connectFromHome {
  text-align: center;
  width: 60%;
  margin: auto;
}

a.linktoconnect {
  display: flex;
  justify-content: center;
}

.commonContainer {
  min-height: 50vh;
}

.toggle {
  position: absolute;
  top: 25px;
  left: 15px;
  cursor: pointer;
  z-index: 999;
  display: none;
}

nav.mobldf {
  display: none;
}

.backgroundimage {
  background-color: #10151b;
  background: url("../public/FinalStrip_dark.jpg")
    no-repeat center center fixed;
  background-size: cover;
  min-height: 600px;
}
.backgroundimagemaindiv {
  width: 86%;
  margin: 0 auto;
  display: flex;
  padding: 100px 0;
}
input.subsInput:focus-visible {
  outline: none;
}
select:focus-visible {
  outline: none;
}
input:focus-visible {
  outline: none;
}
.back-div {
  background: #dddddd61;
  padding: 60px;
}
.back-div h2 {
  margin: 0 0 20px;
  line-height: inherit;
  font-size: 32px;
  color: #000;
}
p.text-14 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  text-transform: lowercase;
  width: 580px;
  margin: 0 auto 20px;
  line-height: 24px;
  font-family: MarkPro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: #000;
}
button.Connectbtn {
  background: #ed6221;
  color: #fff;
  border: none;
  font-size: 16px;
  border-radius: 70px;
  padding: 10px 30px;
  display: inline-block;
  font-weight: 600;
}
button.Connectbtn:focus {
  outline: none;
}
.eventform h1.h1.eventtitle {
  font-size: 28px;
}
.eventform .addevent {
  padding: 11px 0;
  width: 140px;
  font-size: 14px;
}
.editDeletebtn1 button {
  margin: 0 10px;
}
.editDeletebtn button:focus {
  outline: none;
}

.bout-title {
  display: flex;
  flex-wrap: wrap;
}
.bout-title .container {
  width: 31%;
  padding: 17px;
  background: rgb(255, 94, 0);
  background: linear-gradient(
    180deg,
    rgba(255, 94, 0, 1) 0%,
    rgba(157, 52, 0, 1) 100%
  );
  color: #fff;
  border-radius: 0px;
}
.bout-cursorcls {
  cursor: pointer;
}
hr.boder-new {
  border: 1px solid #dddddd7a;
  width: 100%;
}
.editDeletebtn-B button {
  background: none;
  padding: 3px;
}
.editDeletebtn-B i {
  font-size: 25px;
}
.Newboutsec {
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 22px;
  margin: 9px 0 21px;
  font-weight: 600;
  width: 100%;
}
.Fencertoptitle {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  padding: 0 22px;
}
span.closebtn {
  float: right;
  cursor: pointer;
}
h1.Customtitle {
  margin: 0 0 15px;
  font-size: 22px;
  font-weight: bold;
}
form.Profileform input {
  border: 1px solid #ddd;
  height: 45px;
  padding: 0 10px;
  font-size: 13px;
}
form.Profileform select {
  border: 1px solid #ddd;
  height: 45px;
  padding: 0 10px;
  font-size: 13px;
}

.Profileform label {
  position: absolute;
  top: -22px;
  left: 0px;
  font-size: 13px;
  color: black;
  transition: all 0.5s ease-in-out;
}
.Bouttitle {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin: 13px 0 13px;
}
.dialog-div-N .MuiDialog-paperScrollPaper {
  display: unset;
  max-height: unset;
  flex-direction: column;
}
.c-cl-t {
  height: 450px;
  overflow-y: scroll;
  padding: 30px;
  overflow-x: hidden;
}
form.fencerviewdiv input {
  border: 1px solid #ddd;
  height: 45px;
  padding: 0 10px;
  font-size: 13px;
}

form.fencerviewdiv select {
  border: 1px solid #ddd;
  height: 45px;
  padding: 0 10px;
  font-size: 13px;
}

.fencerviewdiv label {
  position: absolute;
  top: -22px;
  left: 0px;
  font-size: 13px;
  color: black;
  transition: all 0.5s ease-in-out;
}
form.fencerviewdiv {
  padding-top: 20px;
}
.contactForm input::placeholder {
  color: #000000d9 !important;
}
.contactForm textarea::placeholder {
  color: #000000d9 !important;
}
.editDeletebtn1 button {
  background: none;
  color: #fff;
  padding: 0;
}
.editDeletebtn1 i {
  font-size: 20px;
}
.font-20 {
  font-size: 26px;
}
p.testerror {
  position: absolute;
  bottom: 10px;
  right: 0;
  font-size: 12px;
  color: #ed6221;
}
p.testerror11 {
  position: absolute;
  bottom: 10px;
  right: 0;
  font-size: 12px;
  color: #ed6221;
}
p.testerror1 {
  position: absolute;
  bottom: 6px;
  right: 0;
  font-size: 12px;
  color: #ed6221;
}
.Please-error {
  position: relative;
  float: left;
  width: 100%;
}
.backgroundimage > div > div {
  z-index: 999;
}
.fencerviewdiv .form-input {
  float: left;
  width: 100%;
}
.form-input {
  float: left;
  width: 100%;
}
button.next-button {
  border-radius: 100px;
  background: #ed6221;
  color: #fff;
  padding: 10px 40px 10px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
}
button.next-button:focus {
  outline: none;
}
button.previous-button {
  background: #ed6221;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 30px;
  margin-bottom: 40px;
  border-radius: 100px;
}
button.previous-button {
  outline: none;
}
.tour {
  margin-top: 60px;
}
h2.headingerofpage1 {
  text-align: center;
  font-weight: bold;
  font-size: 28px;
}
.MuiDialog-paper {
  margin-top: 80px !important;
}
.image_and_namesinvite_new {
  height: 430px;
}
.subscriptionpage {
  max-width: 300px;
  background: linear-gradient(
    180deg,
    rgba(255, 94, 0, 1) 0%,
    rgba(157, 52, 0, 1) 100%
  );
  width: 300px;
  padding: 30px;
  border-radius: 10px;
  margin: 30px 0;
}
.subscr-heading {
  margin-top: 20px;
  margin-bottom: 0;
}
.heading66767 {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 30px !important;
}
.subscriptionpage h1 {
  color: #fff;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 28px;
}
.subscriptionpage h2 {
  color: white;
  font-size: 32px;
}
.subscriptionpage p {
  color: #fff;
  font-size: 20px;
  margin-bottom: 15px;
}
.subscriptionpage button {
  padding: 8px 26px;
  background: #fff;
  border: none;
  border-radius: 100px;
  font-size: 18px;
  font-weight: bold;
  color: #000000b3;
}
.subscriptionpage button {
  outline: none;
}
.swal-button {
  background-color: #ed6221;
  font-size: 13px;
  border-radius: 100px;
  color: white;
}
.image_and_namesinvite1 {
  height: 450px;
}
.eventtitle1 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.icon-pointer {
  cursor: pointer;
}
span.crossTagsDiv2 {
  float: right;
}
form.eventform {
  padding-top: 20px;
}
.Tournament-title-t {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0 10px;
}
.crossTagsDiv-1 {
  float: right;
  margin-right: 5px;
}
.swal-button:not([disabled]):hover {
  background-color: #8e3b14 !important;
}

.loading-content {
  width: 100%;
  text-align: center;
  background-color: #f1f1f1;
}
.subscriptionDataflex {
  display: flex !important;
  gap: 21px;
}
.opacity {
  opacity: 0.4;
}
.subscriptionpageSuccessful {
  max-width: 420px;
  background: #008000b0;
  width: 429px;
  padding: 53px;
  border-radius: 10px;
  margin: 50px 0;
  color: white;
  font-size: 18px;
}

.subscriptionpageRed {
  background: linear-gradient(180deg, rgb(255 0 0) 0%, rgb(157 52 0) 100%);
  max-width: 420px;
  width: 429px;
  padding: 53px;
  border-radius: 10px;
  margin: 50px 0;
  color: white;
  font-size: 18px;
}
.showFencer {
  display: flex;
  background-color: white;
}
.showFencerBouts-1 {
  width: 88%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto
}
.fencer-heading {
  margin-bottom: 20px;
  font-family: poppins;
}
.bout-typelist {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 5px 0;
}
.bout-typelist select {
  width: 15%;
  margin: 0 5px;
}
.fencer-detail {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: white;
  padding-top: 16px;
}
.fencer-detail-flex {
  margin: 0 20px;
  margin-bottom: 10px;
}
.showFencerBouts-2 {
  width: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showFencerBoutsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 0 10%;
  width: 100%;
}
.showFencerBoutsList .container {
  width: 31%;
  padding: 17px;
  background: rgb(255, 94, 0);
  background: linear-gradient(
    180deg,
    rgba(255, 94, 0, 1) 0%,
    rgba(157, 52, 0, 1) 100%
  );
  color: #fff;
  border-radius: 0px;
  cursor: pointer;
}
.connectFromHome b {
  text-transform: uppercase;
}
.back-divs {
  /* background: url(https://static.pexels.com/photos/3797/black-and-white-sport-fight-boxer.jpg); */
  background-position: center;
  background-size: cover;
  padding: 60px;
  border-top: 1px solid #ddd;
}
.back-divs b {
  color: black;
}
.container-width {
  width: 100%;
}
.videoUpload {
  width: 90%;
  background-color: white;
  border-radius: 10px;
  transition: all 0.2s;
}
.videoUpload-section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.videoUpload-section1 h3 {
  margin-top: 0px;
}
.VideoUpload-section2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.videoUpload-videos {
  width: 30%;
  margin: 5px;
  background-color: #000;
}
.videoUpload-videos > video {
  width: 100%;
}
.video-options {
  display: flex;
  justify-content: center;
  border-top: 1px solid white;
}
.video-options button {
  border: none;
  background-color: transparent;
  padding-top: 4px;
}
.video-options button:focus {
  outline: none;
}
.video-label {
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-decoration: underline;
  font-weight: 600;
}
.bout-tabs-padding {
  padding: 0 85px;
}
.tounament-overnight-div {
  display: flex;
  width: 100%;
}
.tounament-overnight-div12 {
  width: 35%;
}
.rre {
  position: absolute !important;
  background: white;
  height: 480px;
  overflow: auto;
  width: 550px;
  left: 28%;
  top: 5px;
  border-radius: 5px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
}
.input-div12 {
  border: 1px solid #ddd;
  min-height: 45px;
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.bout-tagg-span {
  margin: 1.5px 5px;
  background: #333;
  color: white;
  padding: 5px 7px;
  min-width: 33px;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.input-div12 input {
  font-size: 13px !important;
  border: none;
  width: 100px;
}
.input-div12 input:focus-visible {
  border: none;
}
.icon-212 {
  margin-top: 1px;
  margin-left: 3px;
}
.show-list1222 {
  position: absolute;
  top: 45px;
  width: 90px;
  background-color: white;
  z-index: 33;
  border: 1px solid #ddd;
  max-height: 190px;
  overflow: auto;
  text-align: left;
  padding-left: 10px;
}
.input-div12 > :first-child {
  padding-top: 4px;
}
.lesson-btnCreate {
  font-size: 16px;
  border: none;
  outline: none;
  border-radius: 100px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: poppins;
  background: #333;
  height: 48px;
  width: 150px;
  color: #fff !important;
  position: absolute;
  right: 70px;
  top: 0px;
}
.backBtn {
  position: absolute;
  border: 1px solid #000000a8;
  padding: 7px 36px;
  background: white;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  border-radius: 45px;
  height: 40px;
}
.backBtn:hover {
  color: #000;
}
.backbtn-lesson {
  right: -141px;
  top: 9px;
}
.backbtn-lessonview {
  right: 100px;
  top: 97px;
}
.backbtn-createFen {
  right: 120px;
  top: 110px;
}
.lessonview-div {
  width: 85%;
  margin: 20px auto;
  margin-bottom: 30px;
  padding: 20px 40px;
  background-color: white;
  border-radius: 10px;
}
.subscription-continueBtn {
  font-size: 16px;
  border: none;
  outline: none;
  border-radius: 100px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: poppins;
  background: #333;
  height: 48px;
  width: 150px;
  color: #fff !important;
  margin: 10px auto;
}
.lessonform {
  width: auto !important;
}
.button-div122 {
  position: absolute;
  top: 0;
  z-index: 3;
  background-color: white;
  width: 83%;
  height: 70px;
}
.lesson-textarea {
  position: relative;
  background: transparent;
  width: 100%;
  color: rgb(0, 0, 0);
  border: 1px solid #ddd;
  height: 120px;
  padding: 10px;
  font-size: 13px;
  border-radius: 4px;
  outline: none;
}
.switch {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 18px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 2px;
  left: 8px;
  right: 0;
  bottom: 0px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  right: 0;
  bottom: 1px;
  background-color: white;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #ed6221;
}
input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
