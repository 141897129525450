/* Hamburger Menu */
.hamburger {
    float: right;
    width: 3.6rem;
    height: 1.8rem;
    margin-top: .5rem;
    position: absolute;
    display: none;
    cursor: pointer;
    line-height: 5rem;
    z-index: 1500;
    align-self: 'right';
}
  
.hamburger .line {
outline: 1px solid transparent;  /* supposed to remove pixelated border on chrome */
width: 100%;
height: 2px;
background-color: var(--secondary);
position: absolute;
display: block;
right: 0;
transition: all 650ms;
}
  

.hamburger .line:nth-child(1) {
top: 0;
}

.hamburger .line:nth-child(2) {
top: 50%;
margin-top: -1px;
width: 2.8rem;
}

.hamburger .line:nth-child(3) {
top: 100%;
margin-top: -2px;
width: 2rem;
}

.hamburger.open-menu .line:nth-child(1) {
transform: translateY(.8rem) rotate(45deg);
width: 2rem;
}

.hamburger.open-menu .line:nth-child(2) {
opacity: 0;
}

.hamburger.open-menu .line:nth-child(3) {
transform: translateY(-.8rem) rotate(-45deg);
width: 2rem;
}


@media only screen and (max-width: 800px) {
    .hamburger {
    display: none;
    top: 0;
    right: 0;
    }

    .hamburger.open-menu {
        display: none;
        top: 0;
        right: 0;
        }

}