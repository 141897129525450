/* 
https://codepen.io/mirek-marchut/pen/wvqMBvW?editors=1100
https://codepen.io/woranov/pen/NRqLWK 
https://codepen.io/justin107d/pen/bGjmzmM
*/

.bout-tabs {
    /* display: flex; */
    /* flex-wrap: wrap; */
    width: 100%;
    justify-content: center;
    float: left;
}

form.newbout {
    width: 100%;
    float: left;
}
section.bout-tab-content {
    width: 100%;
    float: left;
}
.bout-tabs label.bout-tab-radio {
    padding: 10px 16px;
    cursor: pointer;
    align-self: center;
}

.bout-tabs input.bout-tab-radio {
    display: none;
}

.bout-tabs section {
    order: 1;
    width: 100%;
    font-size: 0.9em;
    display: none;
}

.bout-tabs input.bout-tab-radio:checked + label.bout-tab-radio {
    font-weight: bold;
    color: var(--primary);
    /* border-bottom: 2px solid var(--primary); */
    background-color: #d3d3d391;
    box-shadow: 1px 2px 3px #00000054;
    border-radius: 8px;
}

.bout-tabs input.bout-tab-radio:checked + label.bout-tab-radio + section {
    display: initial;
}












