/* reference:  https://codepen.io/nikstech/pen/xmMxpr */
/* another reference: https://codepen.io/team/css-tricks/pen/XVBLRM?editors=1100 */

.form-input {
	position:relative;
	margin-bottom:25px;
    min-height: 60px;
}

.form-input label{
	position:absolute;
	top:0px;
	left:0px;
	font-size: 1rem;
	color:black;	
	transition: all 0.5s ease-in-out;
}

.form-input input, 
.form-input select{ 
    position: relative;
    border:0;
    border-bottom:1px solid rgb(61, 61, 61);  
    background:transparent;
    width:100%;
    padding:8px 0 5px 0;
    font-size: 1rem;
    color:rgb(0, 0, 0);
}

.form-input input:focus{ 
    border:none;	
    outline:none;
    border-bottom:1px solid var(--accent3);	
}

.form-input input:focus ~ label,
.form-input input:active ~ label,
.form-input select:focus ~ label,
.form-input select:active ~ label {
	top:-22px;
	font-size:13px;	
}

.form-input input[type="date"] {
    /* color: white; */
    color: black;
    transition: color 250ms ease-in;
}

input[type="date"]:focus ,
input[type="date"]:active {
    color: black;
}


.form-input > label.jumped {
    top:-22px;
	font-size: 13px;
}

.form-input input[type="date"].jumped {
    color: black
}

/* .form-input input[type="select"]:focus,
.form-input input[type="select"]:active {
    color: green;
} */

.form-input .arrow {
    top: 0;
    right: 0;
    position:absolute;
    display: block;
    height: 100%;
    width: 1rem;
    background: transparent;
    border: 1;
    pointer-events: none;

}

.form-input .arrow::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-left: .25em solid transparent;
    border-right: .25em solid transparent;
    border-top: .25em solid black;
    z-index: 100;
}

/*  */
/*  */
/* toggle button */

.toggle {
    cursor: pointer;
    /* display: inline-block; */
    margin-bottom: 1rem;
    /* width: 300px; */
    /* justify-content: space-between; */
    display: flex;
    align-items: center;
}
  
.toggle-switch {
    /* display: inline-block; */
    background: #ccc;
    border-radius: 16px;
    width: 58px;
    height: 32px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
    float: right;
}
.toggle-switch:before, .toggle-switch:after {
    content: "";
}

.toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 24px;
    height: 24px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.25s;
}

.toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}

.toggle-checkbox:checked + .toggle-switch {
    background: var(--accent1);
}

.toggle-checkbox:checked + .toggle-switch:before {
    left: 30px;
}

.toggle-checkbox {
    position: absolute;
    visibility: hidden;
}

.toggle-label {
    font-size: 1rem;
    position: relative;
    top: 2px;
    float: left;
}
span.toggle-label {
    font-size: 14px;
    /* margin-right: 12px; */
    width: 200px;
    float: left;
    text-align: left;
}






  