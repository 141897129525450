@media only screen and (min-width: 320px) and (max-width: 767px) {
  .profile-page-1 {
    display: none !important;
  }
  .setting-tabs {
    justify-content: space-between;
  }
  .profile-page-1-display {
    position: absolute !important;
    display: block;
    z-index: 1;
    width: 45%;
    background-color: #575454;
    min-height: 500px;
    color: white;
    text-align: center;
  }
  .profile-page-2 {
    width: 100% !important;
  }
  .menu-bar {
    display: inline !important;
    position: absolute !important;
    z-index: 3 !important;
    top: 8px;
    left: 14px;
    font-size: 20px !important;
  }
  .loader {
    font-size: 12px !important;
  }
  .after-loading {
    font-size: 13px !important;
  }
  .btnSave {
    width: 80px !important;
    font-size: 10px !important;
    padding: 3px 5px !important;
  }
  .magnify {
    right: 240px !important;
    font-size: 15px !important;
  }
  .search-box {
    width: 260px !important;
    /* padding-left: 15px !important; */
  }
  .search-list {
    width: 260px !important;
  }
  .searchbar-btnn {
    width: 60px !important;
    border-radius: 16px !important;
    font-size: 8px !important;
  }
  .ff-container {
    flex-direction: column;
  }
  .ff-1 {
    width: 100% !important;
  }
  .tabs-ss {
    margin: 0 30px !important;
    font-size: 15px !important;
    width: 100px !important;
  }
  .borderbottom {
    width: 100% !important;
  }
  .card-out {
    min-width: 0px !important;
    width: 90% !important;
    box-shadow: none !important;
    margin: 7px auto !important;
  }
  .ff-card {
    font-size: 13px !important;
    margin: 0 15px !important;
  }
  .ff-2 {
    width: 100% !important;
    margin: 0px !important;
  }
  .ff-para12 {
    text-align: center;
    font-size: 15px !important;
  }
  .setting-heading {
    font-size: 17px !important;
    margin-top: 10px !important;
  }
  .subscription-cls-tab2 {
    margin-bottom: 20px;
  }
  .desktop-screen {
    display: none !important;
  }
  .mobile-screen {
    display: block !important;
  }
  .setting-heading-section {
    margin-left: 20px !important;
  }
  .setting-tabs {
    padding: 0px 5px !important;
  }
  .setting-tabs > h4 {
    font-size: 16px !important;
  }
  .subscription-cls {
    margin-bottom: 20px;
    flex-direction: column;
    min-height: 100px !important;
    padding-bottom: 15px;
  }
  .btnP-margin {
    margin-top: 10px;
  }
  .fencer-detail-flex {
    width: 100% !important;
    text-align: center;
  }
  .subsc-width {
    min-width: 45% !important;
  }
  .subsc-width > h3 {
    font-size: 17px;
  }
  .label-cls {
    font-size: 14px !important;
  }
  select#selcted {
    width: 50% !important;
  }
  .flex-btnn {
    height: 20px !important;
    width: 60px !important;
    font-size: 8px !important;
  }
  .sidebar-img {
    width: 80px !important;
    height: 80px !important;
    margin-top: 30px !important;
  }
  .sidebar-h2 {
    margin-top: 5px !important;
    margin-bottom: 15px !important;
    font-size: 20px !important;
    padding: 0 10px;
  }
  li.sideTab {
    padding: 5px !important;
  }
  .magnify-2 {
    font-size: 19px !important;
    margin-top: 5px;
    color: #797575;
    position: absolute;
    top: 8px;
    left: 10px;
  }
  .setting-icon {
    font-size: 16px !important;
  }
  .setting-flex {
    flex-direction: column;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1170px) {
  .menu-bar {
    display: inline !important;
    position: absolute !important;
    z-index: -3;
  }
  .search-box {
    width: 320px !important;
  }
  .search-list {
    width: 320px !important;
  }
  .magnify {
    right: 302px !important;
    font-size: 15px !important;
  }
  .tabs-ss {
    width: 110px !important;
    margin: 0 30px !important;
    font-size: 18px !important;
  }
  .borderbottom {
    width: 100% !important;
  }
  .flex-btnn {
    height: 20px !important;
    width: 63px !important;
    font-size: 9px !important;
  }
  .desktop-screen {
    display: block !important;
  }
  .mobile-screen {
    display: none !important;
  }
  .ff-card {
    font-size: 15px !important;
    margin: 0 8px !important;
  }
  .loader {
    font-size: 13px !important;
  }
  .after-loading {
    font-size: 14px !important;
  }
  .magnify-2 {
    font-size: 19px !important;
    margin-top: 5px;
    color: #797575;
    position: absolute;
    top: 8px;
    left: 10px;
  }
  .sidebar-h2 {
    margin-top: 5px !important;
    margin-bottom: 15px !important;
    font-size: 25px !important;
    padding: 0 10px;
  }
}

.desktop-screen {
  display: block;
}
.mobile-screen {
  display: none;
}
.menu-bar {
  display: none;
  position: absolute !important;
  z-index: -2;
}
.menu-bar-color {
  color: white;
}
.profile-layout {
  display: flex;
  position: relative;
}
.profile-page-1 {
  width: 20%;
  background-color: #575454;
  min-height: 500px;
  color: white;
  text-align: center;
}
.profile-page-2 {
  width: 80%;
}
.sidebar-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-top: 20px;
}

.sidebar-h2 {
  margin-top: 5px;
  margin-bottom: 30px;
  font-size: 30px;
}
.sidebar-links > li {
  list-style: none;
  cursor: pointer;
  margin: 10px 0;
}
.sidebar-links > li > a {
  color: white;
}
.ff-container {
  display: flex;
  width: 100%;
}
.ff-1 {
  width: 55%;
  text-align: center;
  margin: 10px 0;
}
.ff-2 {
  width: 45%;
  margin: 10px 0;
  padding-top: 5px;
}
.search-box-divv {
  background-color: #f1f1f1;
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 10px;
  position: relative;
}
.magnify {
  position: absolute;
  right: 360px;
  color: #797575;
  font-size: 20px !important;
}
.search-box {
  border: 1px solid rgb(212, 209, 209);
  width: 380px;
  height: 37px;
  padding-left: 30px;
  border-radius: 30px;
  font-size: 15px;
}
.close-iconn {
  position: absolute;
  top: 21px;
  right: 20px;
  cursor: pointer;
  color: #797575;
  font-size: 17px !important;
}
.search-list {
  position: absolute;
  top: 50px;
  right: 10px;
  background-color: white;
  width: 380px;
  text-align: center;
  border: 1px solid rgb(212, 209, 209);
  display: block;
  z-index: 1;
}
.searchbar-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  height: 34px;
}
.ff-2-bottom-margin {
  margin-bottom: 18px;
}
.ff-para12 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.card-out {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  width: 90%;
  min-height: 50px;
  margin: 16px auto;
}
.ff-card {
  margin: 0 30px;
  display: flex;
  justify-content: space-between;
  height: 65px;
  align-items: center;
  border-bottom: 1px solid rgb(211, 206, 206);
  font-size: 16px;
}
.width-title {
  width: 25%;
}
.width-title-dateLink{
  display: flex;
  justify-content: space-between;
}
.width-descrip {
  width: 50%;
}
.lessonDetail-div112 {
  display: flex;
  align-items: center;
}
.lessonDetail-backBtn {
  display: inline-block;
  margin:0 50px;
  margin-top: 15px;
  color: #333;
}
.lessonDetail-backBtn:hover {
  color: #333;
}
.lessonlist-backBtn {
  display: inline-block;
  margin-left:50px;
  margin-top: 22px;
  color: #333;
}
.lessonlist-backBtn:hover {
  color: #333;
}
.lessonlist-container{
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  width: 90%;
  margin: 16px auto;
  padding:10px 20px;
  min-height: 150px;
}
.lessonlist-heading{
  margin-top: 5px;
  margin-bottom: 20px;
}
.lessdetail-link {
  color: #333;
  font-size: 14px;
}
.lessdetail-link:hover {
  color: #333;
  text-decoration: underline;
  transition: o.3s ease-out;
}
.input-input {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  position: relative;
  width: 200px;
  margin: auto;
}
.magnify-2 {
  font-size: 19px !important;
  margin-top: 5px;
  color: #797575;
  position: absolute;
  top: 9px;
  left: 0px;
}
.search-box2 {
  border: none;
  border-bottom: 1px solid rgb(211, 206, 206);
  width: 230px;
  height: 40px;
  border-radius: 30px;
  padding: 0 34px;
}
.flex-btnn {
  border: none;
  background-color: #ed6221;
  color: white;
  height: 28px;
  min-width: 85px;
  border-radius: 25px;
  font-size: 13px;
  font-weight: 500;
}
.flex-btnn:focus {
  outline: none;
}
.btn-margin {
  margin: 0 6px;
}
.searchbar-btnn {
  border: none;
  background-color: #ed6221;
  color: white;
  height: 19px;
  width: 80px;
  border-radius: 25px;
  font-size: 12px;
  margin: 0 4px;
}
.searchbar-btnn:focus {
  outline: none;
}
.ff-card:last-child {
  border: none;
}
.data-loading {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.after-loading {
  text-align: center;
  font-size: 16px;
  padding-top: 13px;
}
.loader {
  width: 100%;
  text-align: center;
  background-color: #f1f1f1;
  height: 150px;
  padding: 30px;
  font-size: 24px;
}
.icon-popup img {
  width: 35px;
}
.setting-icon {
  font-size: 20px !important;
  margin-right: 3px;
}
.setting-heading-section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.setting-heading {
  font-size: 23px;
  font-weight: 600;
}
.tab-section {
  display: flex;
  justify-content: center;
}
.tabs-ss {
  cursor: pointer;
  width: 150px;
  margin: 0 50px;
}
.borderbottom {
  height: 4px;
  width: 100%;
  background: #ed6221;
  border-radius: 21px;
  margin-top: 3px;
}

.background {
  background: #575454;
  border-radius: 4px;
}
/* li.active {
  background: #333333;
  padding: 10px;
  border-radius: 10px;
  width: 90%;
  margin: 5px auto;
}
li.sideTab {
  padding: 10px;
  border-radius: 10px;
  width: 90%;
  margin: 5px auto;
} */
.active {
  background: #333333;
  color: white !important;
}
.sideTab {
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  width: 90%;
  margin: 5px auto;
  color: white !important;
}
.sideTab:hover {
  color: white;
}
.subsLink {
  color: #fff;
  float: right;
}
select#selcted {
  width: 30%;
  padding: 10px;
}
.setting-flex {
  display: flex;
}
.setting-flex-border {
  border-bottom: 1px solid #575454;
}
.setting-tabs {
  display: flex;
  align-items: center;
  padding: 0px 15px;
  cursor: pointer;
}
.setting-background {
  border-radius: 7px 7px 0 0;
  background: #575454;
  color: #f1f1f1;
}
.eee {
  border: 1px solid #575454;
}
.setting-tabIcon {
  font-size: 20px !important;
  margin-left: 6px;
}
.subscription-cls {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 140px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
}
.regi-asFen {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 140px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
}
.subscription-cls-tab2 {
  margin-top: 5px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-top: 16px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
}
.label-cls {
  font-size: 17px;
  font-weight: 500;
  text-align: right;
}
.subsc-width {
  width: 32%;
}
.input-margin {
  margin: 0 20px;
}
.btnSave {
  width: 120px;
  color: #fff;
  background: #ed6221;
  font-size: 15px;
  font-family: poppins;
  border-radius: 15px !important;
  border: none;
  padding: 6px 10px;
}
.btn-opacity {
  opacity: 0.4;
}
.btnSave:focus {
  outline: none;
}
