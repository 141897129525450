body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.backtotourament p.initialclass {
  float: left !important;
  margin-right: 5px;
}

.backtotournament {
  text-transform: capitalize;
}

.container.breadcrumbs {
  background: transparent;
  box-shadow: unset;
  text-align: left;
  display: inherit;
}

/* neww ccsss */

@keyframes down-btn {
  0% {
    bottom: 20px;
  }

  100% {
    bottom: 0px;
  }

  0% {
    opacity: 0;
  }

  100% {
    opaicty: 1;
  }
}

@-webkit-keyframes down-btn {
  0% {
    bottom: 20px;
  }

  100% {
    bottom: 0px;
  }

  0% {
    opacity: 0;
  }

  100% {
    opaicty: 1;
  }
}

@-moz-keyframes down-btn {
  0% {
    bottom: 20px;
  }

  100% {
    bottom: 0px;
  }

  0% {
    opacity: 0;
  }

  100% {
    opaicty: 1;
  }
}

@-o-keyframes down-btn {
  0% {
    bottom: 20px;
  }

  100% {
    bottom: 0px;
  }

  0% {
    opacity: 0;
  }

  100% {
    opaicty: 1;
  }
}

body {
  margin: 0;
}

.category-name {
  font-family: sans-serif;
  width: -webkit-fill-available;
  text-align: center;
  font-size: 40px;
}

.card-category-2 ul,
.card-category-3 ul,
.card-category-4 ul,
.card-category-5 ul .card-category-6 ul {
  padding: 0;
}

.card-category-2 ul li,
.card-category-3 ul li,
.card-category-4 ul li,
.card-category-5 ul li,
.card-category-6 ul li {
  list-style-type: none;
  display: inline-block;
  vertical-align: top;
}

.card-category-2 ul li,
.card-category-3 ul li {
  margin: 10px 5px;
}

.card-category-1,
.card-category-2,
.card-category-3,
.card-category-4,
.card-category-5,
.card-category-6 {
  font-family: sans-serif;
  margin-bottom: 45px;
  text-align: center;
}

.card-category-1 div,
.card-category-2 div {
  display: inline-block;
}

.card-category-1 > div,
.card-category-2 > div:not(:last-child) {
  margin: 10px 5px;
  text-align: left;
}

/* Basic Card */
.basic-card {
  width: 300px;
  position: relative;

  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
}

.basic-card .card-content {
  padding: 10px 25px;
  height: 180px;
  width: 100%;
  cursor: pointer;
}

.basic-card .card-title {
  font-size: 25px;
  font-family: "Open Sans", sans-serif;
}

.basic-card .card-text {
  line-height: 1.6;
  color: white !important;
}
.fencer-heading-height {
  display: flex;
  align-items: center;
  height: 70%;
}
.basic-card .card-link {
  padding: 10px 25px;
  width: -webkit-fill-available;
}

.basic-card .card-link a {
  text-decoration: none;
  position: relative;
  padding: 10px 0px;
}

.basic-card .card-link a:after {
  top: 30px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 0;

  -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  -moz-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  -o-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
}

.basic-card .card-link a:hover:after {
  width: 100%;
  left: 0;
}

.basic-card-aqua {
  background-image: linear-gradient(to bottom right, #00bfad, #99a3d4);
}

.basic-card-aqua .card-link {
  border-top: 1px solid #eee;
}

.basic-card-aqua .card-link a:after {
  background: #fff;
}

.basic-card-lips {
  background-image: linear-gradient(to bottom right, #ec407b, #ff7d94);
}

.basic-card-lips .card-content {
  color: #fff;
}

.basic-card-lips .card-link {
  border-top: 1px solid #ff97ba;
}

.basic-card-lips .card-link a:after {
  background: #fff;
}

.basic-card-light {
  border: 1px solid #eee;
}

.basic-card-light .card-title,
.basic-card-light .card-link a {
  color: #636363;
}

.basic-card-light .card-text {
  color: #7b7b7b;
}

.basic-card-light .card-link {
  border-top: 1px solid #eee;
}

.basic-card-light .card-link a:after {
  background: #636363;
}

.basic-card-dark {
  background-image: linear-gradient(to bottom right, #252525, #4a4a4a);
}

.basic-card-dark .card-title,
.basic-card-dark .card-link a {
  color: #eee;
}

.basic-card-dark .card-text {
  color: #dcdcdcdd;
}

.basic-card-dark .card-link {
  border-top: 1px solid #636363;
}

.basic-card-dark .card-link a:after {
  background: #eee;
}

a.link-action-btn {
  color: white !important;
  font-weight: 700;
  font-family: "Poppins";
}

button.edit-btn {
  border: none;
  padding: 3px 16px;
  font-size: 18px;
  width: 50px;
  color: black;
  height: 29px;
  border-radius: 4px;
  /* margin-right: 12px; */
}

button.delete-btn {
  border: none;
  padding: 3px 16px;
  font-size: 18px;
  width: 50px;
  color: rgb(228, 38, 38);
  height: 29px;
  border-radius: 4px;
}

.action-btns {
  margin: 0px 4px;
}
.edit-btn:focus {
  outline: none;
}
.delete-btn:focus {
  outline: none;
}
button.addnewtournamnetbtn {
  padding: 12px 21px;
  border-radius: 100px;
  background: white;
  color: #006b9d;
  font-size: 15px;
  border: none;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.7);
  /* background: #ade5e526; */
  color: #fff;
  font-family: "Poppins";
  font-weight: 500;
  background-image: linear-gradient(to bottom right, #4cddcf, #99a3d4);
}

.fencer-list h1 {
  text-align: center;
  font-family: "Poppins";
}
