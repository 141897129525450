

.search-bar {
    height: 1.5rem;
    width: min(100%, 220px);
    border: none;
    border-bottom: 2px solid;
    border-color: black;
    

}

ul.search-options {
    position: relative;
    z-index: 1;
    margin-top: 0px;
    margin-bottom: 0px;
    justify-content: center;
    padding: 10px;
    list-style: none;
    border: 2px solid;
    border-radius: 5px;
    /* background-color: lightblue; */
}

ul.search-options li:hover{
    background-color: rgb(155, 155, 155);
}

